import React from "react";
import { Helmet } from "react-helmet";
import {Link} from "gatsby";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/tours/Hero";
import Wave from "../../sections/common/Wave";
import Content1 from "../../sections/tours/Content1";
import VideoDemo from "../../sections/common/VideoDemoNew";
import SEO from '../../components/SEO';
import imgC from "../../assets/image/hero/tours.png";

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Take your Tours to the next level"
          description="Let users navigate through a tour with ease using our image based navigational timeline"
          image={imgC}
        />               
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/tours">Tours</Link>
              </li>              
              </ol>
           </nav>
        </div>      
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=tours'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=tours'}></script>         
         
        </Helmet>                 
        <Hero />
        <VideoDemo id="demo" title="" name="" projectId="E3E2DE77-696B-4DC9-B0C4-633FEB0A6DBE" />
        <Wave color="#F7F9FC" /> 
        <Content1 className="grey pt-10 pt-md-20 pt-lg-15 pb-9 pb-md-14 pb-lg-35 overflow-hidden position-relative" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
